import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createBlock(_component_q_inner_loading, { showing: _ctx.loading }, {
    default: _withCtx(() => [
      _createVNode(_component_q_spinner_facebook, {
        size: "30px",
        color: "primary",
        class: "q-ma-sm"
      }),
      _createTextVNode(" Carregando... ")
    ]),
    _: 1
  }, 8, ["showing"]))
}